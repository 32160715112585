'use strict';

var cov_vmkl8apan = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeIndic/TypeIndicCtrl.js',
      hash = 'df31577cd0e8ab0d16343cffd8c7efc391971992',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeIndic/TypeIndicCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 1
        },
        end: {
          line: 112,
          column: 4
        }
      },
      '1': {
        start: {
          line: 7,
          column: 3
        },
        end: {
          line: 7,
          column: 22
        }
      },
      '2': {
        start: {
          line: 8,
          column: 3
        },
        end: {
          line: 8,
          column: 23
        }
      },
      '3': {
        start: {
          line: 9,
          column: 3
        },
        end: {
          line: 9,
          column: 28
        }
      },
      '4': {
        start: {
          line: 10,
          column: 3
        },
        end: {
          line: 10,
          column: 27
        }
      },
      '5': {
        start: {
          line: 11,
          column: 3
        },
        end: {
          line: 11,
          column: 35
        }
      },
      '6': {
        start: {
          line: 12,
          column: 19
        },
        end: {
          line: 12,
          column: 49
        }
      },
      '7': {
        start: {
          line: 13,
          column: 3
        },
        end: {
          line: 13,
          column: 46
        }
      },
      '8': {
        start: {
          line: 15,
          column: 3
        },
        end: {
          line: 19,
          column: 5
        }
      },
      '9': {
        start: {
          line: 16,
          column: 5
        },
        end: {
          line: 16,
          column: 23
        }
      },
      '10': {
        start: {
          line: 17,
          column: 5
        },
        end: {
          line: 17,
          column: 25
        }
      },
      '11': {
        start: {
          line: 18,
          column: 5
        },
        end: {
          line: 18,
          column: 22
        }
      },
      '12': {
        start: {
          line: 21,
          column: 3
        },
        end: {
          line: 36,
          column: 5
        }
      },
      '13': {
        start: {
          line: 22,
          column: 5
        },
        end: {
          line: 22,
          column: 24
        }
      },
      '14': {
        start: {
          line: 23,
          column: 5
        },
        end: {
          line: 30,
          column: 6
        }
      },
      '15': {
        start: {
          line: 24,
          column: 7
        },
        end: {
          line: 28,
          column: 9
        }
      },
      '16': {
        start: {
          line: 29,
          column: 7
        },
        end: {
          line: 29,
          column: 54
        }
      },
      '17': {
        start: {
          line: 31,
          column: 5
        },
        end: {
          line: 35,
          column: 7
        }
      },
      '18': {
        start: {
          line: 38,
          column: 3
        },
        end: {
          line: 42,
          column: 5
        }
      },
      '19': {
        start: {
          line: 39,
          column: 5
        },
        end: {
          line: 39,
          column: 30
        }
      },
      '20': {
        start: {
          line: 40,
          column: 5
        },
        end: {
          line: 40,
          column: 32
        }
      },
      '21': {
        start: {
          line: 41,
          column: 5
        },
        end: {
          line: 41,
          column: 47
        }
      },
      '22': {
        start: {
          line: 43,
          column: 3
        },
        end: {
          line: 70,
          column: 5
        }
      },
      '23': {
        start: {
          line: 44,
          column: 5
        },
        end: {
          line: 44,
          column: 24
        }
      },
      '24': {
        start: {
          line: 45,
          column: 26
        },
        end: {
          line: 49,
          column: 14
        }
      },
      '25': {
        start: {
          line: 51,
          column: 17
        },
        end: {
          line: 53,
          column: 7
        }
      },
      '26': {
        start: {
          line: 52,
          column: 7
        },
        end: {
          line: 52,
          column: 115
        }
      },
      '27': {
        start: {
          line: 55,
          column: 5
        },
        end: {
          line: 58,
          column: 6
        }
      },
      '28': {
        start: {
          line: 56,
          column: 7
        },
        end: {
          line: 56,
          column: 25
        }
      },
      '29': {
        start: {
          line: 57,
          column: 7
        },
        end: {
          line: 57,
          column: 14
        }
      },
      '30': {
        start: {
          line: 60,
          column: 5
        },
        end: {
          line: 60,
          column: 31
        }
      },
      '31': {
        start: {
          line: 61,
          column: 5
        },
        end: {
          line: 61,
          column: 29
        }
      },
      '32': {
        start: {
          line: 62,
          column: 5
        },
        end: {
          line: 69,
          column: 8
        }
      },
      '33': {
        start: {
          line: 63,
          column: 7
        },
        end: {
          line: 67,
          column: 8
        }
      },
      '34': {
        start: {
          line: 64,
          column: 25
        },
        end: {
          line: 64,
          column: 44
        }
      },
      '35': {
        start: {
          line: 65,
          column: 9
        },
        end: {
          line: 65,
          column: 32
        }
      },
      '36': {
        start: {
          line: 66,
          column: 9
        },
        end: {
          line: 66,
          column: 61
        }
      },
      '37': {
        start: {
          line: 68,
          column: 7
        },
        end: {
          line: 68,
          column: 26
        }
      },
      '38': {
        start: {
          line: 72,
          column: 3
        },
        end: {
          line: 108,
          column: 5
        }
      },
      '39': {
        start: {
          line: 73,
          column: 5
        },
        end: {
          line: 107,
          column: 8
        }
      },
      '40': {
        start: {
          line: 85,
          column: 7
        },
        end: {
          line: 87,
          column: 8
        }
      },
      '41': {
        start: {
          line: 86,
          column: 9
        },
        end: {
          line: 86,
          column: 16
        }
      },
      '42': {
        start: {
          line: 88,
          column: 7
        },
        end: {
          line: 88,
          column: 31
        }
      },
      '43': {
        start: {
          line: 89,
          column: 28
        },
        end: {
          line: 94,
          column: 8
        }
      },
      '44': {
        start: {
          line: 95,
          column: 7
        },
        end: {
          line: 106,
          column: 10
        }
      },
      '45': {
        start: {
          line: 96,
          column: 9
        },
        end: {
          line: 103,
          column: 10
        }
      },
      '46': {
        start: {
          line: 97,
          column: 11
        },
        end: {
          line: 100,
          column: 14
        }
      },
      '47': {
        start: {
          line: 101,
          column: 11
        },
        end: {
          line: 101,
          column: 36
        }
      },
      '48': {
        start: {
          line: 102,
          column: 11
        },
        end: {
          line: 102,
          column: 18
        }
      },
      '49': {
        start: {
          line: 104,
          column: 9
        },
        end: {
          line: 104,
          column: 22
        }
      },
      '50': {
        start: {
          line: 105,
          column: 9
        },
        end: {
          line: 105,
          column: 28
        }
      },
      '51': {
        start: {
          line: 109,
          column: 3
        },
        end: {
          line: 111,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 51
          },
          end: {
            line: 2,
            column: 52
          }
        },
        loc: {
          start: {
            line: 2,
            column: 186
          },
          end: {
            line: 112,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 15,
            column: 19
          },
          end: {
            line: 15,
            column: 20
          }
        },
        loc: {
          start: {
            line: 15,
            column: 31
          },
          end: {
            line: 19,
            column: 4
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 20
          },
          end: {
            line: 21,
            column: 21
          }
        },
        loc: {
          start: {
            line: 21,
            column: 36
          },
          end: {
            line: 36,
            column: 4
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 38,
            column: 22
          },
          end: {
            line: 38,
            column: 23
          }
        },
        loc: {
          start: {
            line: 38,
            column: 34
          },
          end: {
            line: 42,
            column: 4
          }
        },
        line: 38
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 43,
            column: 25
          },
          end: {
            line: 43,
            column: 26
          }
        },
        loc: {
          start: {
            line: 43,
            column: 42
          },
          end: {
            line: 70,
            column: 4
          }
        },
        line: 43
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 51,
            column: 66
          },
          end: {
            line: 51,
            column: 67
          }
        },
        loc: {
          start: {
            line: 51,
            column: 74
          },
          end: {
            line: 53,
            column: 6
          }
        },
        line: 51
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 62,
            column: 98
          },
          end: {
            line: 62,
            column: 99
          }
        },
        loc: {
          start: {
            line: 62,
            column: 106
          },
          end: {
            line: 69,
            column: 6
          }
        },
        line: 62
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 72,
            column: 26
          },
          end: {
            line: 72,
            column: 27
          }
        },
        loc: {
          start: {
            line: 72,
            column: 42
          },
          end: {
            line: 108,
            column: 4
          }
        },
        line: 72
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 84,
            column: 8
          },
          end: {
            line: 84,
            column: 9
          }
        },
        loc: {
          start: {
            line: 84,
            column: 21
          },
          end: {
            line: 107,
            column: 6
          }
        },
        line: 84
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 95,
            column: 96
          },
          end: {
            line: 95,
            column: 97
          }
        },
        loc: {
          start: {
            line: 95,
            column: 104
          },
          end: {
            line: 106,
            column: 8
          }
        },
        line: 95
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 23,
            column: 5
          },
          end: {
            line: 30,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 23,
            column: 5
          },
          end: {
            line: 30,
            column: 6
          }
        }, {
          start: {
            line: 23,
            column: 5
          },
          end: {
            line: 30,
            column: 6
          }
        }],
        line: 23
      },
      '1': {
        loc: {
          start: {
            line: 48,
            column: 11
          },
          end: {
            line: 48,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 48,
            column: 40
          },
          end: {
            line: 48,
            column: 62
          }
        }, {
          start: {
            line: 48,
            column: 65
          },
          end: {
            line: 48,
            column: 66
          }
        }],
        line: 48
      },
      '2': {
        loc: {
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 51,
            column: 59
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 51,
            column: 18
          },
          end: {
            line: 51,
            column: 53
          }
        }, {
          start: {
            line: 51,
            column: 57
          },
          end: {
            line: 51,
            column: 59
          }
        }],
        line: 51
      },
      '3': {
        loc: {
          start: {
            line: 52,
            column: 14
          },
          end: {
            line: 52,
            column: 114
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 52,
            column: 14
          },
          end: {
            line: 52,
            column: 40
          }
        }, {
          start: {
            line: 52,
            column: 44
          },
          end: {
            line: 52,
            column: 114
          }
        }],
        line: 52
      },
      '4': {
        loc: {
          start: {
            line: 55,
            column: 5
          },
          end: {
            line: 58,
            column: 6
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 55,
            column: 5
          },
          end: {
            line: 58,
            column: 6
          }
        }, {
          start: {
            line: 55,
            column: 5
          },
          end: {
            line: 58,
            column: 6
          }
        }],
        line: 55
      },
      '5': {
        loc: {
          start: {
            line: 63,
            column: 7
          },
          end: {
            line: 67,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 63,
            column: 7
          },
          end: {
            line: 67,
            column: 8
          }
        }, {
          start: {
            line: 63,
            column: 7
          },
          end: {
            line: 67,
            column: 8
          }
        }],
        line: 63
      },
      '6': {
        loc: {
          start: {
            line: 85,
            column: 7
          },
          end: {
            line: 87,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 85,
            column: 7
          },
          end: {
            line: 87,
            column: 8
          }
        }, {
          start: {
            line: 85,
            column: 7
          },
          end: {
            line: 87,
            column: 8
          }
        }],
        line: 85
      },
      '7': {
        loc: {
          start: {
            line: 96,
            column: 9
          },
          end: {
            line: 103,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 96,
            column: 9
          },
          end: {
            line: 103,
            column: 10
          }
        }, {
          start: {
            line: 96,
            column: 9
          },
          end: {
            line: 103,
            column: 10
          }
        }],
        line: 96
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_vmkl8apan.s[0]++;

angular.module('app').controller('TypeIndicCtrl', function ($scope, SweetAlert, $deltadate, $deltaTypeIndicateur, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope) {
  cov_vmkl8apan.f[0]++;
  cov_vmkl8apan.s[1]++;

  /* $rootScope.processPageRight('3_1');
  if ($rootScope.global_access_page_denied) {
    return;
  } */
  $scope.add = false;
  cov_vmkl8apan.s[2]++;
  $scope.edit = false;
  cov_vmkl8apan.s[3]++;
  $scope.btnAction = false;
  cov_vmkl8apan.s[4]++;
  $scope.MyDataListe = [];
  cov_vmkl8apan.s[5]++;
  $scope.PATH = 'type_indicateur';
  var project = (cov_vmkl8apan.s[6]++, $rootScope.getCurrentProject());
  cov_vmkl8apan.s[7]++;
  $deltaTypeIndicateur.addController($scope);

  cov_vmkl8apan.s[8]++;
  $scope.setAdd = function () {
    cov_vmkl8apan.f[1]++;
    cov_vmkl8apan.s[9]++;

    $scope.add = true;
    cov_vmkl8apan.s[10]++;
    $scope.edit = false;
    cov_vmkl8apan.s[11]++;
    $scope.setEdit();
  };

  cov_vmkl8apan.s[12]++;
  $scope.setEdit = function (item) {
    cov_vmkl8apan.f[2]++;
    cov_vmkl8apan.s[13]++;

    $scope.dbl = false;
    cov_vmkl8apan.s[14]++;
    if (!item) {
      cov_vmkl8apan.b[0][0]++;
      cov_vmkl8apan.s[15]++;

      item = {
        id: -1,
        LIBELLE_TI: '',
        NUM_COULEUR_INDIC: '32,32,32'
      };
      cov_vmkl8apan.s[16]++;
      $scope.listData_TypeIndicateur_bksb.push(item);
    } else {
      cov_vmkl8apan.b[0][1]++;
    }
    cov_vmkl8apan.s[17]++;
    $scope.validateItem = {
      LIBELLE_TI: item.LIBELLE_TI,
      NUM_COULEUR_INDIC: item.NUM_COULEUR_INDIC,
      id: item.id
    };
  };

  cov_vmkl8apan.s[18]++;
  $scope.setCancel = function () {
    cov_vmkl8apan.f[3]++;
    cov_vmkl8apan.s[19]++;

    $scope.isloading = false;
    cov_vmkl8apan.s[20]++;
    $scope.validateItem = null;
    cov_vmkl8apan.s[21]++;
    $deltaTypeIndicateur.removeData({ id: -1 });
  };
  cov_vmkl8apan.s[22]++;
  $scope.setValidData = function (right) {
    cov_vmkl8apan.f[4]++;
    cov_vmkl8apan.s[23]++;

    $scope.dbl = false;
    var validateItem = (cov_vmkl8apan.s[24]++, Object.assign({
      LIBELLE_TI: $scope.validateItem.LIBELLE_TI.trim(),
      NUM_COULEUR_INDIC: $scope.validateItem.NUM_COULEUR_INDIC,
      id: $scope.validateItem.id > 0 ? (cov_vmkl8apan.b[1][0]++, $scope.validateItem.id) : (cov_vmkl8apan.b[1][1]++, 0)
    }, right));

    var dbl = (cov_vmkl8apan.s[25]++, ((cov_vmkl8apan.b[2][0]++, $scope.listData_TypeIndicateur_bksb) || (cov_vmkl8apan.b[2][1]++, [])).find(function (type) {
      cov_vmkl8apan.f[5]++;
      cov_vmkl8apan.s[26]++;

      return (cov_vmkl8apan.b[3][0]++, type.id != validateItem.id) && (cov_vmkl8apan.b[3][1]++, validateItem.LIBELLE_TI.toUpperCase() == type.LIBELLE_TI.toUpperCase());
    }));

    cov_vmkl8apan.s[27]++;
    if (dbl) {
      cov_vmkl8apan.b[4][0]++;
      cov_vmkl8apan.s[28]++;

      $scope.dbl = true;
      cov_vmkl8apan.s[29]++;
      return;
    } else {
      cov_vmkl8apan.b[4][1]++;
    }

    cov_vmkl8apan.s[30]++;
    $scope.error_code = false;
    cov_vmkl8apan.s[31]++;
    $scope.isloading = true;
    cov_vmkl8apan.s[32]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_vmkl8apan.f[6]++;
      cov_vmkl8apan.s[33]++;

      if (data > 0) {
        cov_vmkl8apan.b[5][0]++;

        var newData = (cov_vmkl8apan.s[34]++, validateItem.id > 0);
        cov_vmkl8apan.s[35]++;
        validateItem.id = data;
        cov_vmkl8apan.s[36]++;
        $deltaTypeIndicateur.addData(validateItem, newData);
      } else {
        cov_vmkl8apan.b[5][1]++;
      }
      cov_vmkl8apan.s[37]++;
      $scope.setCancel();
    });
  };

  cov_vmkl8apan.s[38]++;
  $scope.setDeleteData = function (item) {
    cov_vmkl8apan.f[7]++;
    cov_vmkl8apan.s[39]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("FN_TYPINDIC.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_vmkl8apan.f[8]++;
      cov_vmkl8apan.s[40]++;

      if (isconfirm) {
        cov_vmkl8apan.b[6][0]++;
        cov_vmkl8apan.s[41]++;

        return;
      } else {
        cov_vmkl8apan.b[6][1]++;
      }
      cov_vmkl8apan.s[42]++;
      $scope.isloading = true;
      var validateItem = (cov_vmkl8apan.s[43]++, {
        LIBELLE_TI: item.LIBELLE_TI,
        ID_PROJET: item.ID_PROJET,
        NUM_COULEUR_INDIC: item.NUM_COULEUR_INDIC,
        id: item.id
      });
      cov_vmkl8apan.s[44]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_vmkl8apan.f[9]++;
        cov_vmkl8apan.s[45]++;

        if (data < 0) {
          cov_vmkl8apan.b[7][0]++;
          cov_vmkl8apan.s[46]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_vmkl8apan.s[47]++;
          $scope.isloading = false;
          cov_vmkl8apan.s[48]++;
          return;
        } else {
          cov_vmkl8apan.b[7][1]++;
        }
        cov_vmkl8apan.s[49]++;
        item.id = -1;
        cov_vmkl8apan.s[50]++;
        $scope.setCancel();
      });
    });
  };
  cov_vmkl8apan.s[51]++;
  $scope.sortableOptions = {
    connectWith: '.connectList'
  };
});