'use strict';

var cov_1zjfdh61ki = function () {
  var path = '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeActivity/TypeActivCtrl.js',
      hash = '8d8897b0360a72c8ad3368b7f35812d84652fc7d',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/lampp/htdocs/www/OMConsulting/apps-delta/delta-monitoring/client/src/app/views/TypeActivity/TypeActivCtrl.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 234,
          column: 4
        }
      },
      '1': {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 21
        }
      },
      '2': {
        start: {
          line: 10,
          column: 2
        },
        end: {
          line: 10,
          column: 22
        }
      },
      '3': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 27
        }
      },
      '4': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 26
        }
      },
      '5': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 32
        }
      },
      '6': {
        start: {
          line: 14,
          column: 18
        },
        end: {
          line: 14,
          column: 48
        }
      },
      '7': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 43
        }
      },
      '8': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 21,
          column: 4
        }
      },
      '9': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 22
        }
      },
      '10': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 24
        }
      },
      '11': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 21
        }
      },
      '12': {
        start: {
          line: 23,
          column: 2
        },
        end: {
          line: 58,
          column: 4
        }
      },
      '13': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 31,
          column: 5
        }
      },
      '14': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 29,
          column: 8
        }
      },
      '15': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 51
        }
      },
      '16': {
        start: {
          line: 34,
          column: 4
        },
        end: {
          line: 34,
          column: 44
        }
      },
      '17': {
        start: {
          line: 37,
          column: 4
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '18': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 38,
          column: 42
        }
      },
      '19': {
        start: {
          line: 39,
          column: 4
        },
        end: {
          line: 39,
          column: 50
        }
      },
      '20': {
        start: {
          line: 40,
          column: 4
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '21': {
        start: {
          line: 43,
          column: 6
        },
        end: {
          line: 43,
          column: 67
        }
      },
      '22': {
        start: {
          line: 45,
          column: 4
        },
        end: {
          line: 47,
          column: 7
        }
      },
      '23': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 45
        }
      },
      '24': {
        start: {
          line: 53,
          column: 4
        },
        end: {
          line: 57,
          column: 6
        }
      },
      '25': {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 64,
          column: 4
        }
      },
      '26': {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 61,
          column: 29
        }
      },
      '27': {
        start: {
          line: 62,
          column: 4
        },
        end: {
          line: 62,
          column: 31
        }
      },
      '28': {
        start: {
          line: 63,
          column: 4
        },
        end: {
          line: 63,
          column: 44
        }
      },
      '29': {
        start: {
          line: 65,
          column: 2
        },
        end: {
          line: 107,
          column: 4
        }
      },
      '30': {
        start: {
          line: 67,
          column: 4
        },
        end: {
          line: 87,
          column: 5
        }
      },
      '31': {
        start: {
          line: 68,
          column: 6
        },
        end: {
          line: 68,
          column: 48
        }
      },
      '32': {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 53
        }
      },
      '33': {
        start: {
          line: 70,
          column: 6
        },
        end: {
          line: 70,
          column: 44
        }
      },
      '34': {
        start: {
          line: 71,
          column: 6
        },
        end: {
          line: 71,
          column: 44
        }
      },
      '35': {
        start: {
          line: 74,
          column: 6
        },
        end: {
          line: 78,
          column: 7
        }
      },
      '36': {
        start: {
          line: 75,
          column: 8
        },
        end: {
          line: 75,
          column: 49
        }
      },
      '37': {
        start: {
          line: 76,
          column: 8
        },
        end: {
          line: 76,
          column: 54
        }
      },
      '38': {
        start: {
          line: 77,
          column: 8
        },
        end: {
          line: 77,
          column: 15
        }
      },
      '39': {
        start: {
          line: 80,
          column: 6
        },
        end: {
          line: 84,
          column: 9
        }
      },
      '40': {
        start: {
          line: 83,
          column: 8
        },
        end: {
          line: 83,
          column: 32
        }
      },
      '41': {
        start: {
          line: 89,
          column: 25
        },
        end: {
          line: 94,
          column: 5
        }
      },
      '42': {
        start: {
          line: 95,
          column: 4
        },
        end: {
          line: 95,
          column: 30
        }
      },
      '43': {
        start: {
          line: 96,
          column: 4
        },
        end: {
          line: 96,
          column: 28
        }
      },
      '44': {
        start: {
          line: 97,
          column: 4
        },
        end: {
          line: 106,
          column: 7
        }
      },
      '45': {
        start: {
          line: 98,
          column: 6
        },
        end: {
          line: 104,
          column: 7
        }
      },
      '46': {
        start: {
          line: 99,
          column: 24
        },
        end: {
          line: 99,
          column: 43
        }
      },
      '47': {
        start: {
          line: 100,
          column: 8
        },
        end: {
          line: 100,
          column: 31
        }
      },
      '48': {
        start: {
          line: 101,
          column: 8
        },
        end: {
          line: 101,
          column: 35
        }
      },
      '49': {
        start: {
          line: 102,
          column: 8
        },
        end: {
          line: 102,
          column: 35
        }
      },
      '50': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 58
        }
      },
      '51': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 25
        }
      },
      '52': {
        start: {
          line: 109,
          column: 2
        },
        end: {
          line: 145,
          column: 4
        }
      },
      '53': {
        start: {
          line: 110,
          column: 5
        },
        end: {
          line: 144,
          column: 8
        }
      },
      '54': {
        start: {
          line: 122,
          column: 7
        },
        end: {
          line: 124,
          column: 8
        }
      },
      '55': {
        start: {
          line: 123,
          column: 9
        },
        end: {
          line: 123,
          column: 16
        }
      },
      '56': {
        start: {
          line: 125,
          column: 7
        },
        end: {
          line: 125,
          column: 31
        }
      },
      '57': {
        start: {
          line: 126,
          column: 28
        },
        end: {
          line: 131,
          column: 8
        }
      },
      '58': {
        start: {
          line: 132,
          column: 7
        },
        end: {
          line: 143,
          column: 10
        }
      },
      '59': {
        start: {
          line: 133,
          column: 9
        },
        end: {
          line: 140,
          column: 10
        }
      },
      '60': {
        start: {
          line: 134,
          column: 11
        },
        end: {
          line: 137,
          column: 14
        }
      },
      '61': {
        start: {
          line: 138,
          column: 11
        },
        end: {
          line: 138,
          column: 36
        }
      },
      '62': {
        start: {
          line: 139,
          column: 11
        },
        end: {
          line: 139,
          column: 18
        }
      },
      '63': {
        start: {
          line: 141,
          column: 9
        },
        end: {
          line: 141,
          column: 22
        }
      },
      '64': {
        start: {
          line: 142,
          column: 9
        },
        end: {
          line: 142,
          column: 28
        }
      },
      '65': {
        start: {
          line: 147,
          column: 2
        },
        end: {
          line: 149,
          column: 4
        }
      },
      '66': {
        start: {
          line: 151,
          column: 2
        },
        end: {
          line: 233,
          column: 3
        }
      },
      '67': {
        start: {
          line: 162,
          column: 6
        },
        end: {
          line: 162,
          column: 18
        }
      },
      '68': {
        start: {
          line: 165,
          column: 6
        },
        end: {
          line: 165,
          column: 25
        }
      },
      '69': {
        start: {
          line: 166,
          column: 6
        },
        end: {
          line: 166,
          column: 21
        }
      },
      '70': {
        start: {
          line: 167,
          column: 6
        },
        end: {
          line: 167,
          column: 26
        }
      },
      '71': {
        start: {
          line: 168,
          column: 6
        },
        end: {
          line: 168,
          column: 29
        }
      },
      '72': {
        start: {
          line: 169,
          column: 6
        },
        end: {
          line: 169,
          column: 34
        }
      },
      '73': {
        start: {
          line: 170,
          column: 6
        },
        end: {
          line: 170,
          column: 25
        }
      },
      '74': {
        start: {
          line: 177,
          column: 6
        },
        end: {
          line: 177,
          column: 29
        }
      },
      '75': {
        start: {
          line: 178,
          column: 6
        },
        end: {
          line: 178,
          column: 34
        }
      },
      '76': {
        start: {
          line: 180,
          column: 6
        },
        end: {
          line: 184,
          column: 7
        }
      },
      '77': {
        start: {
          line: 181,
          column: 8
        },
        end: {
          line: 181,
          column: 30
        }
      },
      '78': {
        start: {
          line: 182,
          column: 8
        },
        end: {
          line: 182,
          column: 35
        }
      },
      '79': {
        start: {
          line: 183,
          column: 8
        },
        end: {
          line: 183,
          column: 15
        }
      },
      '80': {
        start: {
          line: 185,
          column: 6
        },
        end: {
          line: 188,
          column: 7
        }
      },
      '81': {
        start: {
          line: 186,
          column: 8
        },
        end: {
          line: 186,
          column: 35
        }
      },
      '82': {
        start: {
          line: 187,
          column: 8
        },
        end: {
          line: 187,
          column: 15
        }
      },
      '83': {
        start: {
          line: 190,
          column: 20
        },
        end: {
          line: 192,
          column: 8
        }
      },
      '84': {
        start: {
          line: 191,
          column: 8
        },
        end: {
          line: 191,
          column: 70
        }
      },
      '85': {
        start: {
          line: 193,
          column: 6
        },
        end: {
          line: 197,
          column: 7
        }
      },
      '86': {
        start: {
          line: 194,
          column: 8
        },
        end: {
          line: 194,
          column: 30
        }
      },
      '87': {
        start: {
          line: 196,
          column: 8
        },
        end: {
          line: 196,
          column: 15
        }
      },
      '88': {
        start: {
          line: 199,
          column: 6
        },
        end: {
          line: 199,
          column: 49
        }
      },
      '89': {
        start: {
          line: 201,
          column: 6
        },
        end: {
          line: 206,
          column: 7
        }
      },
      '90': {
        start: {
          line: 202,
          column: 8
        },
        end: {
          line: 202,
          column: 51
        }
      },
      '91': {
        start: {
          line: 203,
          column: 8
        },
        end: {
          line: 203,
          column: 35
        }
      },
      '92': {
        start: {
          line: 205,
          column: 8
        },
        end: {
          line: 205,
          column: 15
        }
      },
      '93': {
        start: {
          line: 208,
          column: 6
        },
        end: {
          line: 212,
          column: 9
        }
      },
      '94': {
        start: {
          line: 214,
          column: 6
        },
        end: {
          line: 214,
          column: 21
        }
      },
      '95': {
        start: {
          line: 215,
          column: 6
        },
        end: {
          line: 215,
          column: 26
        }
      },
      '96': {
        start: {
          line: 220,
          column: 6
        },
        end: {
          line: 227,
          column: 9
        }
      },
      '97': {
        start: {
          line: 221,
          column: 8
        },
        end: {
          line: 225,
          column: 9
        }
      },
      '98': {
        start: {
          line: 222,
          column: 10
        },
        end: {
          line: 222,
          column: 49
        }
      },
      '99': {
        start: {
          line: 224,
          column: 11
        },
        end: {
          line: 224,
          column: 24
        }
      },
      '100': {
        start: {
          line: 226,
          column: 8
        },
        end: {
          line: 226,
          column: 20
        }
      },
      '101': {
        start: {
          line: 229,
          column: 6
        },
        end: {
          line: 229,
          column: 21
        }
      },
      '102': {
        start: {
          line: 230,
          column: 6
        },
        end: {
          line: 230,
          column: 26
        }
      },
      '103': {
        start: {
          line: 231,
          column: 6
        },
        end: {
          line: 231,
          column: 25
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 50
          },
          end: {
            line: 2,
            column: 51
          }
        },
        loc: {
          start: {
            line: 2,
            column: 189
          },
          end: {
            line: 234,
            column: 2
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 17,
            column: 18
          },
          end: {
            line: 17,
            column: 19
          }
        },
        loc: {
          start: {
            line: 17,
            column: 30
          },
          end: {
            line: 21,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 23,
            column: 19
          },
          end: {
            line: 23,
            column: 20
          }
        },
        loc: {
          start: {
            line: 23,
            column: 35
          },
          end: {
            line: 58,
            column: 3
          }
        },
        line: 23
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 40,
            column: 45
          },
          end: {
            line: 40,
            column: 46
          }
        },
        loc: {
          start: {
            line: 40,
            column: 65
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 40
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 60,
            column: 21
          },
          end: {
            line: 60,
            column: 22
          }
        },
        loc: {
          start: {
            line: 60,
            column: 33
          },
          end: {
            line: 64,
            column: 3
          }
        },
        line: 60
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 65,
            column: 24
          },
          end: {
            line: 65,
            column: 25
          }
        },
        loc: {
          start: {
            line: 65,
            column: 36
          },
          end: {
            line: 107,
            column: 3
          }
        },
        line: 65
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 80,
            column: 47
          },
          end: {
            line: 80,
            column: 48
          }
        },
        loc: {
          start: {
            line: 80,
            column: 67
          },
          end: {
            line: 84,
            column: 7
          }
        },
        line: 80
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 97,
            column: 97
          },
          end: {
            line: 97,
            column: 98
          }
        },
        loc: {
          start: {
            line: 97,
            column: 105
          },
          end: {
            line: 106,
            column: 5
          }
        },
        line: 97
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 109,
            column: 25
          },
          end: {
            line: 109,
            column: 26
          }
        },
        loc: {
          start: {
            line: 109,
            column: 41
          },
          end: {
            line: 145,
            column: 3
          }
        },
        line: 109
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 121,
            column: 8
          },
          end: {
            line: 121,
            column: 9
          }
        },
        loc: {
          start: {
            line: 121,
            column: 21
          },
          end: {
            line: 144,
            column: 6
          }
        },
        line: 121
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 132,
            column: 96
          },
          end: {
            line: 132,
            column: 97
          }
        },
        loc: {
          start: {
            line: 132,
            column: 104
          },
          end: {
            line: 143,
            column: 8
          }
        },
        line: 132
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 159,
            column: 11
          },
          end: {
            line: 159,
            column: 12
          }
        },
        loc: {
          start: {
            line: 159,
            column: 23
          },
          end: {
            line: 163,
            column: 5
          }
        },
        line: 159
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 164,
            column: 10
          },
          end: {
            line: 164,
            column: 11
          }
        },
        loc: {
          start: {
            line: 164,
            column: 22
          },
          end: {
            line: 171,
            column: 5
          }
        },
        line: 164
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 172,
            column: 13
          },
          end: {
            line: 172,
            column: 14
          }
        },
        loc: {
          start: {
            line: 172,
            column: 25
          },
          end: {
            line: 216,
            column: 5
          }
        },
        line: 172
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 190,
            column: 41
          },
          end: {
            line: 190,
            column: 42
          }
        },
        loc: {
          start: {
            line: 190,
            column: 53
          },
          end: {
            line: 192,
            column: 7
          }
        },
        line: 190
      },
      '15': {
        name: '(anonymous_15)',
        decl: {
          start: {
            line: 217,
            column: 16
          },
          end: {
            line: 217,
            column: 17
          }
        },
        loc: {
          start: {
            line: 217,
            column: 32
          },
          end: {
            line: 232,
            column: 5
          }
        },
        line: 217
      },
      '16': {
        name: '(anonymous_16)',
        decl: {
          start: {
            line: 220,
            column: 43
          },
          end: {
            line: 220,
            column: 44
          }
        },
        loc: {
          start: {
            line: 220,
            column: 55
          },
          end: {
            line: 227,
            column: 7
          }
        },
        line: 220
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 31,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 31,
            column: 5
          }
        }, {
          start: {
            line: 24,
            column: 4
          },
          end: {
            line: 31,
            column: 5
          }
        }],
        line: 24
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }, {
          start: {
            line: 37,
            column: 4
          },
          end: {
            line: 49,
            column: 5
          }
        }],
        line: 37
      },
      '2': {
        loc: {
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 37,
            column: 41
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 37,
            column: 18
          }
        }, {
          start: {
            line: 37,
            column: 22
          },
          end: {
            line: 37,
            column: 41
          }
        }],
        line: 37
      },
      '3': {
        loc: {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 7
          }
        }, {
          start: {
            line: 45,
            column: 4
          },
          end: {
            line: 47,
            column: 7
          }
        }],
        line: 45
      },
      '4': {
        loc: {
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        }, {
          start: {
            line: 67,
            column: 4
          },
          end: {
            line: 87,
            column: 5
          }
        }],
        line: 67
      },
      '5': {
        loc: {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 78,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 78,
            column: 7
          }
        }, {
          start: {
            line: 74,
            column: 6
          },
          end: {
            line: 78,
            column: 7
          }
        }],
        line: 74
      },
      '6': {
        loc: {
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 74,
            column: 129
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 74,
            column: 10
          },
          end: {
            line: 74,
            column: 43
          }
        }, {
          start: {
            line: 74,
            column: 47
          },
          end: {
            line: 74,
            column: 85
          }
        }, {
          start: {
            line: 74,
            column: 90
          },
          end: {
            line: 74,
            column: 128
          }
        }],
        line: 74
      },
      '7': {
        loc: {
          start: {
            line: 92,
            column: 10
          },
          end: {
            line: 92,
            column: 65
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 92,
            column: 39
          },
          end: {
            line: 92,
            column: 61
          }
        }, {
          start: {
            line: 92,
            column: 64
          },
          end: {
            line: 92,
            column: 65
          }
        }],
        line: 92
      },
      '8': {
        loc: {
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }, {
          start: {
            line: 98,
            column: 6
          },
          end: {
            line: 104,
            column: 7
          }
        }],
        line: 98
      },
      '9': {
        loc: {
          start: {
            line: 122,
            column: 7
          },
          end: {
            line: 124,
            column: 8
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 122,
            column: 7
          },
          end: {
            line: 124,
            column: 8
          }
        }, {
          start: {
            line: 122,
            column: 7
          },
          end: {
            line: 124,
            column: 8
          }
        }],
        line: 122
      },
      '10': {
        loc: {
          start: {
            line: 133,
            column: 9
          },
          end: {
            line: 140,
            column: 10
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 133,
            column: 9
          },
          end: {
            line: 140,
            column: 10
          }
        }, {
          start: {
            line: 133,
            column: 9
          },
          end: {
            line: 140,
            column: 10
          }
        }],
        line: 133
      },
      '11': {
        loc: {
          start: {
            line: 180,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 180,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        }, {
          start: {
            line: 180,
            column: 6
          },
          end: {
            line: 184,
            column: 7
          }
        }],
        line: 180
      },
      '12': {
        loc: {
          start: {
            line: 180,
            column: 10
          },
          end: {
            line: 180,
            column: 53
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 180,
            column: 11
          },
          end: {
            line: 180,
            column: 26
          }
        }, {
          start: {
            line: 180,
            column: 32
          },
          end: {
            line: 180,
            column: 52
          }
        }],
        line: 180
      },
      '13': {
        loc: {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        }, {
          start: {
            line: 185,
            column: 6
          },
          end: {
            line: 188,
            column: 7
          }
        }],
        line: 185
      },
      '14': {
        loc: {
          start: {
            line: 185,
            column: 11
          },
          end: {
            line: 185,
            column: 104
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 185,
            column: 11
          },
          end: {
            line: 185,
            column: 26
          }
        }, {
          start: {
            line: 185,
            column: 31
          },
          end: {
            line: 185,
            column: 69
          }
        }, {
          start: {
            line: 185,
            column: 74
          },
          end: {
            line: 185,
            column: 104
          }
        }],
        line: 185
      },
      '15': {
        loc: {
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 197,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 197,
            column: 7
          }
        }, {
          start: {
            line: 193,
            column: 6
          },
          end: {
            line: 197,
            column: 7
          }
        }],
        line: 193
      },
      '16': {
        loc: {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        }, {
          start: {
            line: 201,
            column: 6
          },
          end: {
            line: 206,
            column: 7
          }
        }],
        line: 201
      },
      '17': {
        loc: {
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 225,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 225,
            column: 9
          }
        }, {
          start: {
            line: 221,
            column: 8
          },
          end: {
            line: 225,
            column: 9
          }
        }],
        line: 221
      },
      '18': {
        loc: {
          start: {
            line: 221,
            column: 12
          },
          end: {
            line: 221,
            column: 74
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 221,
            column: 13
          },
          end: {
            line: 221,
            column: 39
          }
        }, {
          start: {
            line: 221,
            column: 45
          },
          end: {
            line: 221,
            column: 73
          }
        }],
        line: 221
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0, 0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1zjfdh61ki.s[0]++;

angular.module('app').controller('TypeActivCtrl', function ($scope, SweetAlert, $deltadate, $deltaTypeActivity, $translate, $CRUDService, $otherHttpService, $location, $filter, $rootScope, $log) {
  cov_1zjfdh61ki.f[0]++;
  cov_1zjfdh61ki.s[1]++;


  /*$rootScope.processPageRight('2_1');
  if ($rootScope.global_access_page_denied) {
    return;
  }*/

  $scope.add = false;
  cov_1zjfdh61ki.s[2]++;
  $scope.edit = false;
  cov_1zjfdh61ki.s[3]++;
  $scope.btnAction = false;
  cov_1zjfdh61ki.s[4]++;
  $scope.MyDataListe = [];
  cov_1zjfdh61ki.s[5]++;
  $scope.PATH = 'type_activity';
  var project = (cov_1zjfdh61ki.s[6]++, $rootScope.getCurrentProject());
  cov_1zjfdh61ki.s[7]++;
  $deltaTypeActivity.addController($scope);

  cov_1zjfdh61ki.s[8]++;
  $scope.setAdd = function () {
    cov_1zjfdh61ki.f[1]++;
    cov_1zjfdh61ki.s[9]++;

    $scope.add = true;
    cov_1zjfdh61ki.s[10]++;
    $scope.edit = false;
    cov_1zjfdh61ki.s[11]++;
    $scope.setEdit();
  };

  cov_1zjfdh61ki.s[12]++;
  $scope.setEdit = function (item) {
    cov_1zjfdh61ki.f[2]++;
    cov_1zjfdh61ki.s[13]++;

    if (!item) {
      cov_1zjfdh61ki.b[0][0]++;
      cov_1zjfdh61ki.s[14]++;

      item = {
        id: -1,
        LABEL_TYPE: '',
        COLOR: '32,32,32'
      };
      cov_1zjfdh61ki.s[15]++;
      $scope.listData_TypeActivity_bksb.push(item);
    } else {
      cov_1zjfdh61ki.b[0][1]++;
    }
    //$log.log('setEdit');
    //$log.log(item);
    cov_1zjfdh61ki.s[16]++;
    $scope.activity_process.checked = false;
    //$log.log(item.STEPS);

    cov_1zjfdh61ki.s[17]++;
    if ((cov_1zjfdh61ki.b[2][0]++, item.STEPS) && (cov_1zjfdh61ki.b[2][1]++, item.STEPS.length > 0)) {
      cov_1zjfdh61ki.b[1][0]++;
      cov_1zjfdh61ki.s[18]++;

      $scope.activity_process.dataTotal = 0;
      cov_1zjfdh61ki.s[19]++;
      $scope.activity_process.listStep = item.STEPS;
      cov_1zjfdh61ki.s[20]++;
      $scope.activity_process.listStep.forEach(function (element, index) {
        cov_1zjfdh61ki.f[3]++;
        cov_1zjfdh61ki.s[21]++;

        //$log.log('listStep');
        //$log.log(index, element);
        $scope.activity_process.dataTotal += parseInt(element.VALUE);
      });
      cov_1zjfdh61ki.s[22]++;
      if ($scope.activity_process.dataTotal > 0) {
        cov_1zjfdh61ki.b[3][0]++;
        cov_1zjfdh61ki.s[23]++;

        $scope.activity_process.checked = true;
      } else {
        cov_1zjfdh61ki.b[3][1]++;
      }
    } else {
      cov_1zjfdh61ki.b[1][1]++;
    }
    //$log.log($scope.activity_process.dataTotal);
    //$log.log($scope.activity_process.checked);

    cov_1zjfdh61ki.s[24]++;
    $scope.validateItem = {
      LABEL_TYPE: item.LABEL_TYPE,
      COLOR: item.COLOR,
      id: item.id
    };
  };

  cov_1zjfdh61ki.s[25]++;
  $scope.setCancel = function () {
    cov_1zjfdh61ki.f[4]++;
    cov_1zjfdh61ki.s[26]++;

    $scope.isloading = false;
    cov_1zjfdh61ki.s[27]++;
    $scope.validateItem = null;
    cov_1zjfdh61ki.s[28]++;
    $deltaTypeActivity.removeData({ id: -1 });
  };
  cov_1zjfdh61ki.s[29]++;
  $scope.setValidData = function () {
    cov_1zjfdh61ki.f[5]++;
    cov_1zjfdh61ki.s[30]++;


    if (!$scope.activity_process.checked) {
      cov_1zjfdh61ki.b[4][0]++;
      cov_1zjfdh61ki.s[31]++;

      $scope.activity_process.errorStep = false;
      cov_1zjfdh61ki.s[32]++;
      $scope.activity_process.errorStepValue = false;
      cov_1zjfdh61ki.s[33]++;
      $scope.activity_process.dataTotal = 0;
      cov_1zjfdh61ki.s[34]++;
      $scope.activity_process.listStep = [];
    } else {
      cov_1zjfdh61ki.b[4][1]++;
      cov_1zjfdh61ki.s[35]++;


      if ((cov_1zjfdh61ki.b[6][0]++, $scope.activity_process.errorStep) || (cov_1zjfdh61ki.b[6][1]++, $scope.activity_process.errorStepValue) || (cov_1zjfdh61ki.b[6][2]++, $scope.activity_process.dataTotal != 100)) {
        cov_1zjfdh61ki.b[5][0]++;
        cov_1zjfdh61ki.s[36]++;

        $scope.activity_process.errorStep = true;
        cov_1zjfdh61ki.s[37]++;
        $scope.activity_process.errorStepValue = true;
        cov_1zjfdh61ki.s[38]++;
        return;
      } else {
        cov_1zjfdh61ki.b[5][1]++;
      }

      cov_1zjfdh61ki.s[39]++;
      $scope.activity_process.listStep.forEach(function (element, index) {
        cov_1zjfdh61ki.f[6]++;
        cov_1zjfdh61ki.s[40]++;

        //$log.log('listStep');
        //$log.log(index, element);
        element.NIVSETP = index;
      });
    }

    var validateItem = (cov_1zjfdh61ki.s[41]++, {
      LABEL_TYPE: $scope.validateItem.LABEL_TYPE,
      COLOR: $scope.validateItem.COLOR,
      id: $scope.validateItem.id > 0 ? (cov_1zjfdh61ki.b[7][0]++, $scope.validateItem.id) : (cov_1zjfdh61ki.b[7][1]++, 0),
      STEPS: $scope.activity_process.listStep
    });
    cov_1zjfdh61ki.s[42]++;
    $scope.error_code = false;
    cov_1zjfdh61ki.s[43]++;
    $scope.isloading = true;
    cov_1zjfdh61ki.s[44]++;
    $CRUDService.save($scope.PATH, { action: 'MiseAJour', valeur: $filter('json')(validateItem) }, function (data) {
      cov_1zjfdh61ki.f[7]++;
      cov_1zjfdh61ki.s[45]++;

      if (data > 0) {
        cov_1zjfdh61ki.b[8][0]++;

        var newData = (cov_1zjfdh61ki.s[46]++, validateItem.id > 0);
        cov_1zjfdh61ki.s[47]++;
        validateItem.id = data;
        cov_1zjfdh61ki.s[48]++;
        validateItem.update = true;
        cov_1zjfdh61ki.s[49]++;
        validateItem.delete = true;
        cov_1zjfdh61ki.s[50]++;
        $deltaTypeActivity.addData(validateItem, newData);
      } else {
        cov_1zjfdh61ki.b[8][1]++;
      }
      cov_1zjfdh61ki.s[51]++;
      $scope.setCancel();
    });
  };

  cov_1zjfdh61ki.s[52]++;
  $scope.setDeleteData = function (item) {
    cov_1zjfdh61ki.f[8]++;
    cov_1zjfdh61ki.s[53]++;

    SweetAlert.swal({
      title: $translate.instant('COMMON.CONFIRM'),
      // text: $translate.instant("TYPACTIVITY.CONFIRM_DELETE"),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c8c8c8',

      confirmButtonText: $translate.instant('COMMON.NO'),
      cancelButtonText: $translate.instant('COMMON.YES'),
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isconfirm) {
      cov_1zjfdh61ki.f[9]++;
      cov_1zjfdh61ki.s[54]++;

      if (isconfirm) {
        cov_1zjfdh61ki.b[9][0]++;
        cov_1zjfdh61ki.s[55]++;

        return;
      } else {
        cov_1zjfdh61ki.b[9][1]++;
      }
      cov_1zjfdh61ki.s[56]++;
      $scope.isloading = true;
      var validateItem = (cov_1zjfdh61ki.s[57]++, {
        LABEL_TYPE: item.LABEL_TYPE,
        ID_PROJET: item.ID_PROJET,
        COLOR: item.COLOR,
        id: item.id
      });
      cov_1zjfdh61ki.s[58]++;
      $CRUDService.delet($scope.PATH, { action: 'supp', valeur: $filter('json')(validateItem) }, function (data) {
        cov_1zjfdh61ki.f[10]++;
        cov_1zjfdh61ki.s[59]++;

        if (data < 0) {
          cov_1zjfdh61ki.b[10][0]++;
          cov_1zjfdh61ki.s[60]++;

          SweetAlert.swal({
            title: $translate.instant('COMMON.ERROR_DELETE'),
            text: $translate.instant('COMMON.TITLE_ERROR_DELETE')
          });
          cov_1zjfdh61ki.s[61]++;
          $scope.isloading = false;
          cov_1zjfdh61ki.s[62]++;
          return;
        } else {
          cov_1zjfdh61ki.b[10][1]++;
        }
        cov_1zjfdh61ki.s[63]++;
        item.id = -1;
        cov_1zjfdh61ki.s[64]++;
        $scope.setCancel();
      });
    });
  };

  cov_1zjfdh61ki.s[65]++;
  $scope.sortableOptions = {
    connectWith: '.connectList'
  };

  cov_1zjfdh61ki.s[66]++;
  $scope.activity_process = {
    checked: false,
    dataTotal: 0,
    step: '',
    stepValue: '',
    errorStep: false,
    errorStepValue: false,
    listStep: [],
    check: function check() {
      cov_1zjfdh61ki.f[11]++;
      cov_1zjfdh61ki.s[67]++;

      //$log.log('check');
      //$log.log(this.checked);
      this.init();
    },
    init: function init() {
      cov_1zjfdh61ki.f[12]++;
      cov_1zjfdh61ki.s[68]++;

      this.dataTotal = 0;
      cov_1zjfdh61ki.s[69]++;
      this.step = "";
      cov_1zjfdh61ki.s[70]++;
      this.stepValue = "";
      cov_1zjfdh61ki.s[71]++;
      this.errorStep = false;
      cov_1zjfdh61ki.s[72]++;
      this.errorStepValue = false;
      cov_1zjfdh61ki.s[73]++;
      this.listStep = [];
    },
    addStep: function addStep() {
      var _this = this;

      cov_1zjfdh61ki.f[13]++;
      cov_1zjfdh61ki.s[74]++;

      //$log.log('addStep');
      //$log.log(this.step);
      //$log.log(this.stepValue);

      this.errorStep = false;
      cov_1zjfdh61ki.s[75]++;
      this.errorStepValue = false;

      cov_1zjfdh61ki.s[76]++;
      if ((cov_1zjfdh61ki.b[12][0]++, this.step == "") || (cov_1zjfdh61ki.b[12][1]++, this.stepValue == "")) {
        cov_1zjfdh61ki.b[11][0]++;
        cov_1zjfdh61ki.s[77]++;

        this.errorStep = true;
        cov_1zjfdh61ki.s[78]++;
        this.errorStepValue = true;
        cov_1zjfdh61ki.s[79]++;
        return;
      } else {
        cov_1zjfdh61ki.b[11][1]++;
      }
      cov_1zjfdh61ki.s[80]++;
      if ((cov_1zjfdh61ki.b[14][0]++, !this.stepValue) || (cov_1zjfdh61ki.b[14][1]++, typeof this.stepValue == 'undefined') || (cov_1zjfdh61ki.b[14][2]++, parseInt(this.stepValue) > 100)) {
        cov_1zjfdh61ki.b[13][0]++;
        cov_1zjfdh61ki.s[81]++;

        this.errorStepValue = true;
        cov_1zjfdh61ki.s[82]++;
        return;
      } else {
        cov_1zjfdh61ki.b[13][1]++;
      }

      var tmpList = (cov_1zjfdh61ki.s[83]++, this.listStep.filter(function (stepItem) {
        cov_1zjfdh61ki.f[14]++;
        cov_1zjfdh61ki.s[84]++;

        return stepItem.STEP.toUpperCase() == _this.step.toUpperCase();
      }));
      cov_1zjfdh61ki.s[85]++;
      if (tmpList.length > 0) {
        cov_1zjfdh61ki.b[15][0]++;
        cov_1zjfdh61ki.s[86]++;

        this.errorStep = true;
        //$log.log(this.step+' -- Existe');
        cov_1zjfdh61ki.s[87]++;
        return;
      } else {
        cov_1zjfdh61ki.b[15][1]++;
      }

      cov_1zjfdh61ki.s[88]++;
      this.dataTotal += parseInt(this.stepValue);
      //$log.log('dataTotal', this.dataTotal);
      cov_1zjfdh61ki.s[89]++;
      if (this.dataTotal > 100) {
        cov_1zjfdh61ki.b[16][0]++;
        cov_1zjfdh61ki.s[90]++;

        this.dataTotal -= parseInt(this.stepValue);
        cov_1zjfdh61ki.s[91]++;
        this.errorStepValue = true;
        //$log.log(this.dataTotal+' > 100');
        cov_1zjfdh61ki.s[92]++;
        return;
      } else {
        cov_1zjfdh61ki.b[16][1]++;
      }

      cov_1zjfdh61ki.s[93]++;
      this.listStep.push({
        NIVSETP: this.listStep.length,
        STEP: this.step,
        VALUE: this.stepValue
      });

      cov_1zjfdh61ki.s[94]++;
      this.step = "";
      cov_1zjfdh61ki.s[95]++;
      this.stepValue = "";
    },
    deleteStep: function deleteStep(item) {
      var _this2 = this;

      cov_1zjfdh61ki.f[15]++;
      cov_1zjfdh61ki.s[96]++;

      //$log.log('deleteStep');
      //$log.log(item);
      this.listStep = this.listStep.filter(function (stepItem) {
        cov_1zjfdh61ki.f[16]++;
        cov_1zjfdh61ki.s[97]++;

        if ((cov_1zjfdh61ki.b[18][0]++, stepItem.STEP == item.STEP) && (cov_1zjfdh61ki.b[18][1]++, stepItem.VALUE == item.VALUE)) {
          cov_1zjfdh61ki.b[17][0]++;
          cov_1zjfdh61ki.s[98]++;

          _this2.dataTotal -= parseInt(item.VALUE);
          //$log.log('dataTotal', this.dataTotal);
          cov_1zjfdh61ki.s[99]++;
          return false;
        } else {
          cov_1zjfdh61ki.b[17][1]++;
        }
        cov_1zjfdh61ki.s[100]++;
        return true;
      });
      //$log.log(this.listStep);
      cov_1zjfdh61ki.s[101]++;
      this.step = "";
      cov_1zjfdh61ki.s[102]++;
      this.stepValue = "";
      cov_1zjfdh61ki.s[103]++;
      this.valid = false;
    }
  };
});